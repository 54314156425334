import React from "react";
import {
  characterFormat1,
  characterFormat2,
  iconFormat1,
  iconFormat2,
  character,
  icon,
  foodCharacter,
  foodIcon,
  square,
  waterCharacter,
  waterIcon,
} from "../assets/images/index";
const ImageInstruction = () => {
  return (
    <div className="mt-24 max-w-7xl mx-auto">
      <div className="mx-6 text-[#2F4F4F]">
        <h1 className="text-2xl text-orange-500 text-center">
          Gift a deed icon / character design specification
        </h1>
        <div className="mt-4">
          <p>
            Please follow below guidelines to create marker and symbol for your
            custom category. The purpose of this guidelines is to bring
            consistency and a cleaner look of the application.
          </p>
        </div>
        <div className="my-4">
          <span className="text-xl font-bold">Attributes</span>
          <p>
            Icon or Character artwork can populate the entire asset space, or
            you can design and position artwork elements such as custom category
            onto the key line grid. When placing your artwork, use key lines as
            a guideline.
          </p>
          <p className="font-bold">
            When creating your artwork, ensure it conforms to the following
            guidelines:
          </p>
        </div>
        <div className="w-1/4">
          <ul className="">
            <li className="py-4 border-b-2">Final size: 80px x 80px</li>
            <li className="py-4 border-b-2">Format: 32-bit PNG</li>
            <li className="py-4 border-b-2">Color space: sRGB</li>
            <li className="py-4 border-b-2">Max file size: 5KB</li>
            <li className="py-4 border-b-2">
              Shape: Circle and location marker
            </li>
            <li className="py-4 border-b-2">Shadow: None</li>
          </ul>
        </div>
        <div>
          <p className="font-bold my-4">
            Please refer following images(while creating character image):
          </p>
        </div>
        <div className="flex justify-between">
          <img width={450} height={100} src={characterFormat1} alt="character" />
          <img src={characterFormat2} alt="character" />
        </div>
        <div>
          <p className="font-bold my-4">
            Please refer following images(while creating icon image):
          </p>
        </div>
        <div className="flex">
          <img src={iconFormat1} alt="icon" />
          <img src={iconFormat2} alt="icon" />
        </div>
        <div>
          <p className="font-bold my-4">
            Examples of icon and character image for 'Food' and 'Water'
            categories are as follows:
          </p>
        </div>
        <div className="w-2/4 my-10">
          <div className="flex justify-between">
            <div className="">
              <img src={foodIcon} alt="icon" />
              <span>Icon for food</span>
            </div>
            <div className="">
              <img src={foodCharacter} alt="icon" />
              <span>Character for food</span>
            </div>
          </div>
        </div>
        <div className="w-2/4 my-10">
          <div className="flex justify-between">
            <div>
              <img src={waterIcon} alt="icon" />
              <span>Icon for water</span>
            </div>
            <div>
              <img src={waterCharacter} alt="icon" />
              <span>Character for water</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageInstruction;
