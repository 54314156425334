import React from "react";

const Footer = () => {
  return (
    <>
      <div className="bg-[#f0f0f0] p-4">
        <p className="text-center">Gift-A-Deed &copy; 2023</p>
      </div>
    </>
  );
};

export default Footer;
