import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { logo } from "../assets/images";
import { FaTag, FaPowerOff } from "react-icons/fa6";
import { FaFile } from "react-icons/fa";
import { GrTree } from "react-icons/gr";
import { useAuth } from "../AuthContext";

const Navbar = () => {
  const location = useLocation();
  // const [isActive, setIsActive] = useState("TAG");
  const { logout } = useAuth();

  const isActive = (route) => {
    return location.pathname === route;
  };
  return (
    <nav className="bg-white shadow-md shadow-slate-200 dark:bg-white fixed top-0 w-full z-40">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <div className="flex items-center">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse cursor-pointer"
          >
            <img src={logo} className="h-10" alt="Gift a deed Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-orange-500">
              Gift-A-Deed
            </span>
          </Link>
          <div
            className="hidden w-full md:block md:w-auto ml-10"
            id="navbar-default"
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
              <li>
                <Link
                  to="/"
                  className={`${
                    isActive("/") ? "text-orange-500" : "text-gray-900"
                  } flex py-2 px-3  rounded md:bg-transparent md:te md:p-0 hover:text-orange-500`}
                >
                  <FaTag className="text-2xl" />
                  Tag A Deed
                </Link>
              </li>
              <li>
              <Link
                  to="/custom-category"
                  className={`${
                    isActive("/custom-category") ? "text-orange-500" : "text-gray-900"
                  } flex py-2 px-3  rounded md:bg-transparent md:te md:p-0 hover:text-orange-500`}
                >
                  <GrTree className="text-2xl" />
                  Custom Category
                </Link>
              </li>
              <li>
              <Link
                  to="/reports"
                  className={`${
                    isActive("/reports") ? "text-orange-500" : "text-gray-900"
                  } flex py-2 px-3  rounded md:bg-transparent md:te md:p-0 hover:text-orange-500`}
                >
                  <FaFile className="text-2xl" />
                  Reports
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div className="lg:inline-block md:inline-block sm:hidden mobile:hidden">
          <span
            className="flex items-center text-lg text-gray-500 cursor-pointer hover:text-orange-500"
            onClick={()=>logout("Logout successful")}
          >
            <FaPowerOff className="text-xl mr-2" />
            Logout
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
