import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { logo } from "../../assets/images";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import CustomToast from "../../components/CustomToast";
import { toast } from "react-toastify";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { API_SERVER_URL } from "../../constants";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(4, "Password minimum 4 characters")
    .max(10, "Password maximum length is 10")
    .required("Password is required"),
});

// const validationSchema = Yup.object({
//   email: Yup.string()
//     .email("Please enter a valid email address")
//     .required("Email is required"),
//   password: Yup.string()
//     .min(4, "Password must be at least 4 characters long")
//     .max(10, "Password cannot exceed 10 characters")
//     .matches(
//       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,10}$/,
//       "Password should include at least one uppercase letter, one lowercase letter, one number, and one special character."
//     )
//     .required("Password is required"),
// });

const Login = () => {
  const [isShow, setIsShow] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // console.log(values);
      setLoading(true);
      try {
        let palylod = { email: values.email, password: values.password };
        // Make an API call using Axios
        const response = await axios.post(
          `${API_SERVER_URL}/api/gc/auth`,
          palylod
        );

        // Handle the API response as needed
        console.log("API Response:", response?.data?.ResultData);
        if (response?.data?.ResultMessage === "Logged in successfully") {
          let id = response?.data?.ResultData?.admin_id;
          let token = response?.data?.ResultData?.access_token;
          login(id, token);
          toast.success("Logged in successfully");
          setTimeout(() => {
            setLoading(false);
            navigate("/");
          }, 500);
        }
        // navigate("/groupconsole");
      } catch (error) {
        // Handle API call errors
        console.log(
          "Login catch Error ---> ",
          error?.response?.data?.ResultMessage
        );
        toast.error(error?.response?.data?.ResultMessage);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="">
        <section className="bg-gray-50 dark:bg-white">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
            <img className="w-28 h-auto mr-2" src={logo} alt="logo" />
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0  dark:border-gray-100">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl text-center font-normal leading-tight tracking-tight text-gray-500 md:text-2xl ">
                  Group Creator Login
                </h1>
                <form
                  onSubmit={formik.handleSubmit}
                  className="space-y-4 md:space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className={`bg-gray-50 border ${
                        formik.errors.email && formik.touched.email
                          ? "border-red-500"
                          : "border-gray-300"
                      } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                      placeholder="Email"
                      required=""
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                  <div className="relative">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Password
                    </label>
                    <input
                      type={isShow ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Password"
                      className={`bg-gray-50 border ${
                        formik.errors.password && formik.touched.password
                          ? "border-red-500"
                          : "border-gray-300"
                      } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                      required=""
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {isShow ? (
                      <VscEyeClosed
                        onClick={() => setIsShow(!isShow)}
                        className="text-xl absolute right-4 top-10"
                      />
                    ) : (
                      <VscEye
                        onClick={() => setIsShow(!isShow)}
                        className="text-xl absolute right-4 top-10"
                      />
                    )}
                    {formik.errors.password && formik.touched.password && (
                      <p className="text-red-500 text-sm mt-1">
                        {formik.errors.password}
                      </p>
                    )}
                  </div>

                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full text-white bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    {isLoading ? (
                      <>
                        <div className="flex justify-center">
                          <svg
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="mr-2 animate-spin"
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                          </svg>
                        </div>
                      </>
                    ) : (
                      <>Log In</>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
