import React, { useEffect, useState } from "react";
import { FaCirclePlus } from "react-icons/fa6";
import Footer from "../components/Footer";
import axios from "axios";
import { API_SERVER_URL, IMAGE_URL } from "../constants";
import { useAuth } from "../AuthContext";
import AddCustomCategoryModal from "../components/AddCustomCategoryModal";
import "../assets/css/Modal.css";
import Swal from "sweetalert2";
import EditCustomCategoryModal from "../components/EditCustomCategoryModal";
const CustomCategory = () => {
  const [customCategoryList, setCustomCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({
    groupId: "",
    CategoryName: "",
    Icon_path: "",
    Character_path: "",
  });
  const { getDetails, logout } = useAuth();
  const { id, token } = getDetails();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    getCustomCatogeryList();
    setIsModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    getCustomCatogeryList();
    setIsEditModalOpen(false);
  };

  // Get custom category list by id
  const getCustomCatogeryList = () => {
    try {
      setIsLoading(true);
      const payload = {
        id: id,
      };
      axios
        .post(`${API_SERVER_URL}/api/gc/customcategory/getAll`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setCustomCategoryList(response?.data?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error --> ", error);
          setIsLoading(false);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
      setIsLoading(setIsLoading);
    }
  };

  useEffect(() => {
    getCustomCatogeryList(); // once call
  }, []);

  const deleteCategory = async (id) => {
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/gc/customcategory/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response deleteCategory --> ", response);

      // Check the response status here
      if (response.status === 200) {
        Swal.fire({
          title: "Deleted!",
          text: "Custom category deleted successfully",
          icon: "success",
        });
      }
    } catch (error) {
      console.log("Error --> ", error);

      if (error?.response && error?.response?.status === 403) {
        // Handle session expired here
        console.log("Session Expired. Logging out...");
        // Call your logout function here if needed
        Swal.fire({
          title: "Error",
          text: "Unable to delete category. Please try again later.",
          icon: "error",
        });
      } else {
        // Handle other errors
        // Swal.fire({
        //   title: "Error",
        //   text: "Unable to delete category. Please try again later.",
        //   icon: "error",
        // });
      }
    }
  };

  async function confirmDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete category",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Make API call to delete the item
        try {
          await deleteCategory(id);
          getCustomCatogeryList(); // Update the list if needed
        } catch (error) {
          console.error("Error deleting item:", error);
        }
      }
    });
  }

  console.log(customCategoryList);

  return (
    <>
      <div className="mt-24"></div>
      <div className="min-h-screen">
        <div className="max-w-7xl mx-auto">
          <div>
            <p className="text-center text-2xl text-orange-500 mt-5">
              Custom Category
            </p>
          </div>
          <div className="flex justify-end">
            <span
              className="px-5 py-2 text-white bg-blue-600 cursor-pointer rounded flex items-center"
              onClick={openModal}
            >
              <FaCirclePlus className="text-lg mr-1" /> Add Custom Category
            </span>
          </div>
          {/* Conditionally render the modal based on isModalOpen */}
          {isModalOpen && (
            <AddCustomCategoryModal
              isOpen={isModalOpen}
              closeModal={closeModal}
            />
          )}
          {isEditModalOpen && (
            <EditCustomCategoryModal
              isEditOpen={isEditModalOpen}
              closeEditModal={closeEditModal}
              editCategory={editCategory}
            />
          )}
          <div>
            <>
              {!isLoading ? (
                customCategoryList?.length > 0 ? (
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border">
                      <thead className="text-xs text-gray-500 uppercase bg-gray-50 dark:bg-gray-500 dark:text-gray-200">
                        <tr>
                          <th scope="col" className="px-6 py-3 border">
                            SR. NO.
                          </th>
                          <th scope="col" className="px-6 py-3 border">
                            GROUP NAME
                          </th>
                          <th scope="col" className="px-6 py-3 border">
                            CATEGORY NAME
                          </th>
                          <th scope="col" className="px-6 py-3 border">
                            ICON
                          </th>
                          <th scope="col" className="px-6 py-3 border">
                            CHARACTER
                          </th>
                          <th scope="col" className="px-6 py-3 border">
                            ACTIONS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {customCategoryList.map((item, index) => (
                          <tr className="odd:bg-white  even:bg-gray-50  border-b">
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-950 whitespace-nowrap border"
                            >
                              {index + 1}
                            </th>
                            <td className="px-6 py-4 text-gray-950 border">
                              {item.groupMaster.Name}
                            </td>
                            <td className="px-6 py-4 text-gray-950 border">
                              {item.Name}
                            </td>
                            <td className="px-6 py-4 text-gray-950 border">
                              {item.Icon_Path && (
                                <img
                                  src={`${IMAGE_URL}/${item.Icon_Path}`}
                                  alt="Icon"
                                  className="w-12 h-12"
                                />
                              )}
                            </td>
                            <td className="px-6 py-4 text-gray-950 border">
                              {item.Map_Pin_Path && (
                                <img
                                  src={`${IMAGE_URL}/${item.Map_Pin_Path}`}
                                  alt="Character"
                                  className="w-12 h-12"
                                />
                              )}
                            </td>
                            <td className="px-6 py-4 border">
                              <button
                                type="button"
                                onClick={() => {
                                  console.log(item);
                                  setEditCategory({
                                    groupId: item?.groupMaster?.Id,
                                    Id: item?.Id,
                                    CategoryName: item?.Name,
                                    Icon_path: item?.Icon_Path,
                                    Character_path: item?.Map_Pin_Path,
                                  });
                                  openEditModal();
                                }}
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                onClick={() => confirmDelete(item.Id)}
                                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                    <div>
                      <h1 className="text-center text-2xl">No record yet</h1>
                    </div>
                  </>
                )
              ) : (
                <>
                  <div className="flex justify-center">
                    <div className="border-gray-300 h-10 w-10 animate-spin rounded-full border-4 border-t-orange-500" />
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
      <div className="static w-full bottom-0">
        <Footer />
      </div>
    </>
  );
};

export default CustomCategory;
