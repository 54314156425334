import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RxCross2 } from "react-icons/rx";
import { IoIosInformationCircle } from "react-icons/io";
import axios from "axios";
import { API_SERVER_URL, IMAGE_URL } from "../constants";
import { useAuth } from "../AuthContext";
import { toast } from "react-toastify";
import { MdEdit } from "react-icons/md";

const EditCustomCategoryModal = ({
  isEditOpen,
  closeEditModal,
  editCategory,
}) => {
  const validationSchema = Yup.object({
    groupId: Yup.number().required("Select Group is required"),
    categoryName: Yup.string().required("Category Name is required"),
    iconImage: Yup.mixed()
      .test(
        "fileType",
        "Only JPG, JPEG, and PNG files are allowed",
        (value) => {
          return (
            typeof value === "string" ||
            !value ||
            (value &&
              ["image/jpeg", "image/jpg", "image/png"].includes(value.type))
          );
        }
      )
      .test("fileSize", "Image should not be larger than 5KB", (value) => {
        return (
          typeof value === "string" || !value || (value && value.size <= 5120)
        ); // 5KB in bytes
      }),
    characterImage: Yup.mixed()
      .test(
        "fileType",
        "Only JPG, JPEG, and PNG files are allowed",
        (value) => {
          return (
            typeof value === "string" ||
            !value ||
            (value &&
              ["image/jpeg", "image/jpg", "image/png"].includes(value.type))
          );
        }
      )
      .test("fileSize", "Image should not be larger than 5KB", (value) => {
        return (
          typeof value === "string" || !value || (value && value.size <= 5120)
        ); // 5KB in bytes
      }),
  });

  const { getDetails, logout } = useAuth();
  const { id, token } = getDetails();

  const [groupList, setGroupList] = useState([]);

  console.log("Edit category --> ", editCategory);

  const formik = useFormik({
    initialValues: {
      groupId: "",
      categoryName: "",
      iconImage: null,
      characterImage: null,
    },
    validationSchema,
    onSubmit: (values) => {
      // console.log("Form submitted with values:", values);
      updateCustomCategory(values);
    },
  });

  useEffect(() => {
    // Update formik's initial values when editCategory changes
    formik.setValues({
      groupId: editCategory?.groupId || "",
      categoryName: editCategory?.CategoryName || "",
      iconImage: `${editCategory.Icon_path}`,
      characterImage: `${editCategory.Character_path}`,
    });
  }, [editCategory]); // Run the effect whenever editCategory changes

  const handleReset = () => {
    formik.resetForm();
  };

  // Get group list by id
  const getGroupList = () => {
    try {
      const payload = {
        id: id,
      };

      axios
        .post(`${API_SERVER_URL}/api/gc/grouplist/list`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log("Response getGroupList --> ", response);
          setGroupList(response?.data?.data);
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  useEffect(() => {
    getGroupList(); // once call
  }, []);

  // Generate option list
  const generateOptionList = () => {
    return groupList.map((item) => (
      <option key={item?.id} value={item?.id}>
        {item?.name}
      </option>
    ));
  };

  const updateCustomCategory = (data) => {
    try {
      console.log("Data --> ", data);
      const formData = new FormData();

      // Append data to the FormData object
      formData.append("group_id", data.groupId);
      formData.append("cat_nm", data.categoryName);
      formData.append("icon_upload", data.iconImage);
      formData.append("character_upload", data.characterImage);

      axios
        .put(
          `${API_SERVER_URL}/api/gc/customcategory/update/${editCategory?.Id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Response update custom category --> ", response);
          toast.success("Category updated successfully");
          closeEditModal();
        })
        .catch((error) => {
          console.log("Error --> ", error?.response?.status);
          if (error?.response?.data?.error === "Category already exists") {
            // toast.error("Category already exists");
            // Set formik error for categoryName
            formik.setFieldError("categoryName", "Category already exists");
          }
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  // console.log("Icon file --> ", typeof formik.values.iconImage);

  return (
    <div className={`custom-modal ${isEditOpen ? "open" : "closed"}`}>
      <div className="modal-content my-10">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-between">
            <h2 className="font-bold text-2xl">Edit Category</h2>
            <div>
              <RxCross2
                onClick={closeEditModal}
                alt=""
                className="cursor-pointer"
              />
            </div>
          </div>
          <hr className="my-6 text-gray-400" />
          <div className="flex">
            <div className="w-1/3 text-right pr-4">
              <label htmlFor="groupId" className="font-bold">
                Select Group:
              </label>
            </div>
            <div className="w-2/3">
              <select
                className="form-select w-full border border-gray-400 rounded p-1"
                id="groupId"
                name="groupId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.groupId}
                required
              >
                <option value="" selected disabled hidden>
                  Select Group
                </option>
                {generateOptionList()}
              </select>
              {formik.touched.groupId && formik.errors.groupId && (
                <div className="text-red-500">{formik.errors.groupId}</div>
              )}
            </div>
          </div>
          <div className="flex my-4">
            <div className="w-1/3 text-right pr-4">
              <label htmlFor="categoryName" className="font-bold">
                Category Name:
              </label>
            </div>
            <div className="w-2/3">
              <input
                type="text"
                id="categoryName"
                name="categoryName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.categoryName}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
              {formik.touched.categoryName && formik.errors.categoryName && (
                <div className="text-red-500">{formik.errors.categoryName}</div>
              )}
            </div>
          </div>
          <div className="flex my-4">
            <div className="w-1/3 text-right pr-4">
              <label htmlFor="iconImage" className="font-bold">
                Icon Image:
              </label>
            </div>
            <div className="w-2/3">
              <input
                type="file"
                id="iconImage"
                name="iconImage"
                onChange={(event) => {
                  formik.setFieldValue(
                    "iconImage",
                    event.currentTarget.files[0]
                  );
                }}
              />

              {formik.touched.iconImage && formik.errors.iconImage && (
                <div className="text-red-500">{formik.errors.iconImage}</div>
              )}

              {formik.values.iconImage &&
                typeof formik.values.iconImage === "object" && (
                  <img
                    src={URL.createObjectURL(formik.values.iconImage)}
                    alt="Icon"
                    className="mt-2 rounded-lg"
                    style={{ maxWidth: "100px" }}
                  />
                )}

              {formik.values.iconImage &&
                typeof formik.values.iconImage === "string" && (
                  <img
                    src={`${IMAGE_URL}/${formik.values.iconImage}`} // Directly use the URL
                    alt="Icon"
                    className="mt-2 rounded-lg"
                    style={{ maxWidth: "100px" }}
                  />
                )}
            </div>
          </div>

          <div className="flex my-4">
            <div className="w-1/3 text-right pr-4">
              <label htmlFor="characterImage" className="font-bold">
                Character Image:
              </label>
            </div>
            <div className="w-2/3">
              <input
                type="file"
                id="characterImage"
                name="characterImage"
                onChange={(event) => {
                  formik.setFieldValue(
                    "characterImage",
                    event.currentTarget.files[0]
                  );
                }}
              />

              {formik.touched.characterImage &&
                formik.errors.characterImage && (
                  <div className="text-red-500">
                    {formik.errors.characterImage}
                  </div>
                )}

              {formik?.values?.characterImage &&
                typeof formik?.values?.characterImage === "object" && (
                  <img
                    src={URL.createObjectURL(formik.values?.characterImage)}
                    alt="character"
                    className="mt-2 rounded-lg"
                    style={{ maxWidth: "100px" }}
                  />
                )}

              {formik.values.characterImage &&
                typeof formik.values.characterImage === "string" && (
                  <img
                    // src={formik.values.characterImage} // Directly use the URL
                    src={`${IMAGE_URL}/${formik.values.characterImage}`}
                    alt="character"
                    className="mt-2 rounded-lg"
                    style={{ maxWidth: "100px" }}
                  />
                )}
            </div>
          </div>

          <div>
            <p className="flex mt-10 text-blue-600 underline cursor-pointer">
              <IoIosInformationCircle className="text-2xl" alt="" /> Click to
              see upload image instructions
            </p>
          </div>
          <hr className="my-6 text-gray-400" />
          <div className="text-right flex justify-end">
            <button
              type="submit"
              className="text-white flex bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2   focus:outline-none dark:focus:ring-blue-800"
            >
              <MdEdit className="text-xl" alt="" /> Edit
            </button>
            {/* <button
              type="button"
              onClick={handleReset}
              className="text-white flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2   focus:outline-none dark:focus:ring-blue-800"
            >
              <GrPowerReset className="text-xl" alt="" /> Reset
            </button> */}
            <button
              type="button"
              onClick={() => {
                handleReset();
                closeEditModal();
              }}
              className="text-white flex bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2   focus:outline-none dark:focus:ring-blue-800"
            >
              <RxCross2 className="text-xl" alt="" /> Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCustomCategoryModal;
