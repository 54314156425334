import axios from "axios";
import React, { useState } from "react";
import { API_SERVER_URL } from "../constants";
import { useAuth } from "../AuthContext";
import Swal from "sweetalert2";

const SubDeedSuggestion = ({ categoryName, categoryId, closeSubTypeModal }) => {
  const [suggestName, setSuggestName] = useState("");
  const [error, setError] = useState("");

  const { getDetails, logout } = useAuth();
  const { id, token } = getDetails();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!suggestName.trim().length > 0) {
      setError("Suggestion is required");
    } else {
      let payload = {
        typeId: categoryId,
        name: suggestName,
      };
      try {
        axios
          .post(`${API_SERVER_URL}/api/gc/deed/subtypes/suggest`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response?.status === 200) {
              Swal.fire({
                title: `Subtype suggestion created successfully`,
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
              });
            }
            console.log("Suggest Response --> ", response);
          })
          .catch((error) => {
            console.log("Error --> ", error);
            if (error?.response && error?.response?.status === 403) {
              logout("Session Expired. Login again");
            }
          });
      } catch (error) {
        console.log("Error --> ", error);
      }
      closeSubTypeModal();
    }
  };

  const handleChange = (e) => {
    setSuggestName(e.target.value);
    setError("");
  };

  return (
    <>
      <div className="modal-overlay fixed top-10 inset-0 z-50 bg-opacity-50 flex justify-start max-h-80">
        <div className="bg-white p-4 rounded-md w-80 border m-4">
          <form onSubmit={handleSubmit}>
            <div className="flex justify-between mb-3">
              <p className="text-lg font-medium">Suggest Preferences</p>
              <button
                type="button"
                onClick={closeSubTypeModal}
                className="text-gray-400 px-4 py-2 rounded-md"
              >
                X
              </button>
            </div>
            <hr />
            <h1 className="my-2">Category : {categoryName}</h1>
            <hr />
            <h1>Suggestion</h1>
            <input
              type="text"
              name="suggestName"
              value={suggestName}
              onChange={handleChange}
              className={`m-3 py-2 px-2 border-b-2 ${
                error ? "border-red-500" : ""
              }`}
              placeholder="Enter suggestion"
            />
            {error && <p className="text-red-500">{error}</p>}
            <hr />
            <div className="flex justify-center my-4">
              <button
                type="button"
                onClick={handleSubmit}
                className="bg-slate-200 text-gray-700 text-base font-semibold rounded p-1 px-2"
              >
                OK
              </button>
              <button
                type="button"
                onClick={closeSubTypeModal}
                className="bg-slate-200 ml-4 text-gray-700 text-base font-semibold rounded p-1 px-2"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SubDeedSuggestion;
