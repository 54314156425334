import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import { GOOGLE_KEY, LIBRARIES } from "../app_constants";
// import "./map4.css";
const containerStyle = {
  marginTop:"-10px",
  width: "100vw",
  height: "90vh",
};

function GoogleMaps({ location, setLocation, details, setDetails }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_KEY,
    libraries: LIBRARIES,
  });

  const [makerPosition, setMarkerPosition] = useState({});
  const [isInfo, setIsInfo] = useState(false);
  // const [details, setDetails] = useState({
  //   address: "Baner",
  //   city: "Pune",
  //   state: "Maharashtra",
  //   country: "India",
  // });

  const onClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const position = { lat, lng };
    setMarkerPosition(position);
    setLocation(position);
    setIsInfo(true);
    console.log(isInfo);
  };

  const onDragEnd = async (event) => {
    try {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      console.log("lat, lng --> ", lat, lng);

      const response = await Geocode.fromLatLng(lat, lng);
      const address = response.results[0].formatted_address;

      let city, state, country;

      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case "locality":
              city = response.results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = response.results[0].address_components[i].long_name;
              break;
            case "country":
              country = response.results[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }

      setDetails({
        ...details,
        address: address,
        city: city || "",
        state: state || "",
        country: country || "",
      });
    } catch (error) {
      console.error("Error fetching address:", error);
      setDetails({
        ...details,
        address: "",
        city: "",
        state: "",
        country: "",
      });
    }
  };

  useEffect(() => {
    Geocode.setApiKey(GOOGLE_KEY);
    Geocode.setLanguage("en");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
  }, []);

  useEffect(() => {
    setLocation(location);
    const getAddress = async () => {
      try {
        const response = await Geocode.fromLatLng(location.lat, location.lng);
        const address = response.results[0].formatted_address;

        let city, state, country;

        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }

        setDetails({
          ...details,
          address: address,
          city: city || "",
          state: state || "",
          country: country || "",
        });
      } catch (error) {
        setDetails({
          ...details,
          address: "",
          city: "",
          state: "",
          country: "",
        });
      }
    };
    getAddress();
  }, [location]);

  // console.log("location --> ", location);

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={14}>
      {/* Child components, such as markers, info windows, etc. */}
      <Marker
        position={location}
        draggable={true}
        onDragEnd={onDragEnd}
        onClick={onClick}
      ></Marker>
      {isInfo === true && (
        <InfoWindow
          position={makerPosition}
          onCloseClick={() => setIsInfo(false)}
        >
          <div>{details?.address}</div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMaps);
