import React from "react";
import { FaDownload } from "react-icons/fa";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

const AllGroupExcelConveter = ({
  jsonData,
  excelFileName,
  excelTitle,
  selectedGroupId,
}) => {
  const convertJsonToExcel = () => {
    const formatDate = (dateString) => {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return new Date(dateString).toLocaleDateString("en-US", options);
    };
    const modifiedData = jsonData.map((item) => ({
      "Group Id": item.id,
      "Group Name": item.name,
      "Group Description": item.description,
      "Group Creation Date Time": formatDate(item.created_at),
    }));

    let creatorName = jsonData[0]
      ? `${jsonData[0]?.user?.Fname} ${jsonData[0]?.user?.Lname}`
      : "";

    const ws = XLSX.utils.json_to_sheet(modifiedData, { origin: "A7" });

    // Set title
    ws["C1"] = { t: "s", v: excelTitle };
    ws["A3"] = { t: "s", v: "Creator Name" };
    ws["B3"] = { t: "s", v: creatorName };
    ws["C5"] = { t: "s", v: "All Groups" };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, excelFileName, { compression: true });
  };

  const handleDownloadClick = () => {
    if (selectedGroupId !== null) {
      convertJsonToExcel();
    } else {
      toast.error("Please select a group");
    }
  };

  return (
    <div>
      <button
        data-toggle="tooltip"
        data-placement="top"
        title="Download List"
        className="text-white flex ml-4 mt-2 bg-orange-500 hover:text-black font-medium rounded text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none dark:focus:ring-blue-800"
        onClick={handleDownloadClick}
      >
        <FaDownload className="text-base mr-1" /> Download
      </button>
    </div>
  );
};

export default AllGroupExcelConveter;
