import React, { useContext, useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import Footer from "../components/Footer";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { API_SERVER_URL } from "../constants";
import AllGroupExcelConveter from "../components/AllGroupExcelConveter";
import GroupMembersExcelConvetor from "../components/GroupMembersExcelConvetor";
import CustomCategExcelConvetor from "../components/CustomCategExcelConvetor";
import TaggedDeedsExcelConvertor from "../components/TaggedDeedsExcelConvertor";
import FullfilledDeedsExcelConvetor from "../components/FullfilledDeedsExcelConvetor";

const Reports = () => {
  const [groupList, setGroupList] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const [allGroup, setAllGroup] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [customCategory, setCustomCategory] = useState([]);
  const [taggedDeed, setTaggedDeed] = useState([]);
  const [fullfilledDeeds, setFullfilledDeeds] = useState([]);
  const { getDetails, logout } = useAuth();
  const { id, token } = getDetails();

  // Get group list by id
  const getGroupList = () => {
    try {
      const payload = {
        id: id,
      };

      axios
        .post(`${API_SERVER_URL}/api/gc/grouplist/list`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log("Response getGroupList --> ", response);
          setGroupList(response?.data?.data);
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  useEffect(() => {
    getGroupList(); // once call
  }, []);

  // Generate option list
  const generateOptionList = () => {
    return groupList.map((item) => (
      <option key={item?.id} value={item?.id}>
        {item?.name}
      </option>
    ));
  };

  // Get all group list by id
  const getAllGroupList = () => {
    try {
      const payload = {
        id: id,
      };
      axios
        .post(`${API_SERVER_URL}/api/gc/report/allgroup`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("Response getAllGroupList --> ", response);
          setAllGroup(response.data.data);
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  // Get group memebers list by group id
  const getGroupMembersList = () => {
    try {
      const payload = {
        group_id: selectedGroupId,
      };
      axios
        .post(`${API_SERVER_URL}/api/gc/report/group/allmembers`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("Response getGroupMembersList --> ", response);
          let userData = response.data.data;
          let groupList = response.data.groupList;
          setGroupMembers({ userData: userData, groupList: groupList });
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  // Get custom categories by group id
  const getCustomCategotyList = () => {
    try {
      const payload = {
        group_id: selectedGroupId,
      };
      axios
        .post(
          `${API_SERVER_URL}/api/gc/report/group/custom/categories`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Response getCustomCategotyList --> ", response);
          setCustomCategory(response?.data?.data);
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  // Get tagged deed list by group id
  const getTaggedDeedList = () => {
    try {
      const payload = {
        group_id: selectedGroupId,
      };
      axios
        .post(`${API_SERVER_URL}/api/gc/report/group/tagged/deeds`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("Response getTaggedDeedList --> ", response);
          let userData = response.data.data;
          let groupList = response.data.groupList;
          setTaggedDeed({ userData: userData, groupList: groupList });
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  // Get fullfill deed list by group id
  const getFullfilledDeedList = () => {
    try {
      const payload = {
        group_id: selectedGroupId,
      };
      axios
        .post(
          `${API_SERVER_URL}/api/gc/report/group/fullfilled/deed`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Response getFullfilledDeedList --> ", response);
          let userData = response.data.data;
          let groupList = response.data.groupList;
          setFullfilledDeeds({ userData: userData, groupList: groupList });
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  const handleGroupChange = (event) => {
    setSelectedGroupId(event.target.value);
  };

  useEffect(() => {
    if (selectedGroupId !== null) {
      getAllGroupList();
      getGroupMembersList();
      getCustomCategotyList();
      getTaggedDeedList();
      getFullfilledDeedList();
    }
  }, [selectedGroupId]);

  return (
    <>
      <div className="mt-24">
        <div className="max-w-6xl lg:mx-auto mb-20 md:mx-10 sm:mx-10 mobile:mx-10">
          <div>
            <p className="text-center text-2xl text-orange-500 mt-5">
              Download Reports
            </p>
          </div>
          <div>
            <div className="border border-gray-300 rounded-lg mt-5">
              <div>
                <p className="bg-[#f7f7f7] p-4 border-b-2">Group</p>
              </div>
              <div className="m-5">
                <p className="">Select a group to generate report.</p>
                <div className="text-center">
                  <select
                    name="categorys"
                    id="categorys"
                    className="p-2 rounded-md cursor-pointer border border-gray-400"
                    value={selectedGroupId}
                    onChange={handleGroupChange}
                  >
                    <option value="Deed 1" selected disabled hidden>
                      Select Category
                    </option>
                    {generateOptionList()}
                  </select>
                </div>
              </div>
              <div>
                <p className="bg-[#f7f7f7] p-4 border-t-2">
                  Note: Group selection is not must for all groups reports.
                </p>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-x-4">
            <div className="">
              <div className="border border-gray-300 rounded-lg mt-5">
                <div>
                  <p className="bg-[#f7f7f7] p-4 border-b-2">All Groups</p>
                </div>
                <div className="m-5">
                  <p className="">
                    Click download button to get all groups information.
                  </p>
                </div>
                <div className="bg-[#f7f7f7] border-t-2 flex">
                  <AllGroupExcelConveter
                    jsonData={allGroup}
                    excelFileName={"allGroup.xlsx"}
                    excelTitle="Gift A Deed"
                    selectedGroupId={selectedGroupId}
                  />
                  {/* <ConvertToExcel
                    dataToCovert={allGroup}
                    filename="allGroup.xlsx"
                    btnName="Download"
                  /> */}
                  {/* <button
                    type="button"
                    className="text-white flex ml-4 mt-2 bg-orange-500 hover:text-black  font-medium rounded text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none dark:focus:ring-blue-800"
                  >
                    <FaDownload className="text-base mr-1" /> Download
                  </button> */}
                </div>
              </div>
            </div>
            <div>
              <div className="border border-gray-300 rounded-lg mt-5">
                <div>
                  <p className="bg-[#f7f7f7] p-4 border-b-2">Group Members</p>
                </div>
                <div className="m-5">
                  <p className="">
                    Click download button to get all groups members information
                    of selected group.
                  </p>
                </div>
                <div className="bg-[#f7f7f7] border-t-2 flex">
                  <GroupMembersExcelConvetor
                    jsonData={groupMembers}
                    excelFileName={"GroupMember.xlsx"}
                    excelTitle="Gift A Deed"
                    selectedGroupId={selectedGroupId}
                  />
                  {/* <button
                    type="button"
                    className="text-white flex ml-4 mt-2 bg-orange-500 hover:text-black  font-medium rounded text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none dark:focus:ring-blue-800"
                  >
                    <FaDownload className="text-base mr-1" /> Download
                  </button> */}
                </div>
              </div>
            </div>
            <div>
              <div className="border border-gray-300 rounded-lg mt-5">
                <div>
                  <p className="bg-[#f7f7f7] p-4 border-b-2">
                    Custom Categories
                  </p>
                </div>
                <div className="m-5">
                  <p className="">
                    Click download button to get data related to custom
                    categories of selected group.
                  </p>
                </div>
                <div className="bg-[#f7f7f7] border-t-2">
                  <CustomCategExcelConvetor
                    jsonData={customCategory}
                    excelFileName={"CustomCategoryList.xlsx"}
                    excelTitle="Gift A Deed"
                    selectedGroupId={selectedGroupId}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="border border-gray-300 rounded-lg mt-5">
                <div>
                  <p className="bg-[#f7f7f7] p-4 border-b-2">Tagged Deeds</p>
                </div>
                <div className="m-5">
                  <p className="">
                    Click download button to get data related to tagged deeds of
                    selected group.
                  </p>
                </div>
                <div className="bg-[#f7f7f7] border-t-2">
                  <TaggedDeedsExcelConvertor
                    jsonData={taggedDeed}
                    excelFileName={"TaggedDeedsList.xlsx"}
                    excelTitle="Gift A Deed"
                    selectedGroupId={selectedGroupId}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="border border-gray-300 rounded-lg mt-5">
                <div>
                  <p className="bg-[#f7f7f7] p-4 border-b-2">Fulfilled Deeds</p>
                </div>
                <div className="m-5">
                  <p className="">
                    Click download button to get data related to fulfilled deeds
                    of selected group.
                  </p>
                </div>
                <div className="bg-[#f7f7f7] border-t-2">
                  <FullfilledDeedsExcelConvetor
                    jsonData={fullfilledDeeds}
                    excelFileName={"FullFillDeedsList.xlsx"}
                    excelTitle="Gift A Deed"
                    selectedGroupId={selectedGroupId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="static w-full bottom-0">
        <Footer />
      </div>
    </>
  );
};

export default Reports;
