// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s ease;
}

.custom-modal.open {
  visibility: visible;
  opacity: 1;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.full-screen {
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B,EAAE,wCAAwC;EACxE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,4CAA4C;AAC9C;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".custom-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 999;\n  visibility: hidden;\n  opacity: 0;\n  transition: visibility 0s, opacity 0.2s ease;\n}\n\n.custom-modal.open {\n  visibility: visible;\n  opacity: 1;\n}\n\n.modal-content {\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  max-width: 100%;\n  max-height: 100%;\n  overflow: hidden;\n}\n\n.full-screen {\n  width: 100%;\n  height: 100%;\n  max-width: none;\n  max-height: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
