/** @format */

import React, { useEffect, useState } from "react";
import { placeholder_image } from "../assets/images";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { useFormik } from "formik";
import * as Yup from "yup";
import GoogleMaps from "../components/GoogleMap";
import Places from "../components/Places";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { API_SERVER_URL } from "../constants";
import { toast } from "react-toastify";
import Select from "react-select";
import Swal from "sweetalert2";
import { renderToString } from "react-dom/server";
import SubDeedSuggestion from "../components/SubDeedSuggestion";
import "./tagadeed.css";

const TagADeed = () => {
  const [quantity, setQuantity] = useState({});
  const [quantityUpdated, setquantityUpdated] = useState({});
  const [output, setOutput] = useState([]);
  const [renderedQuantity, setRenderedQuantity] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubTypeOpen, setIsSubTypeOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState({
    address: "Baner",
    city: "Pune",
    state: "Maharashtra",
    country: "India",
  });

  const { getDetails, logout } = useAuth();
  const { id, token } = getDetails();

  const [groupList, setGroupList] = useState([]);
  const [deedList, setDeedList] = useState([]);
  const [subDeedList, setSubDeedList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [all_individuals, setAllIndividuals] = useState("N");
  const [all_groups, setAllGroups] = useState("N");
  const [firstSubTypQty, setFirstSubTypQty] = useState(1);
  const [arrLen, setArrLen] = useState(0);
  const [optionList, setOptionList] = useState([
    { value: "All Groups", label: "All Groups" },
    { value: "All individual users", label: "All individual users" },
  ]);
  const options = [
    { value: "All Groups", label: "All Groups" },
    { value: "All individual users", label: "All individual users" },
  ];

  const schemaWithArrLen = Yup.object().shape({
    group: Yup.string().required("Group is required"),
    category: Yup.string().required("Category is required"),
    preference: Yup.array()
      .required("Preference is required")
      .min(1, "At least one preference must be selected"),
    needyPeople: Yup.number()
      .required("Number of needy people is required")
      .min(1, "Number of needy people must be at least 1")
      .max(100000, "Number of needy people must be at least 1"),
    deedValidity: Yup.number().min(1).max(72),
  });

  const schemaWithoutArrLen = Yup.object().shape({
    group: Yup.string().required("Group is required"),
    category: Yup.string().required("Category is required"),
    preference: Yup.array(),
    needyPeople: Yup.number()
      .required("Number of needy people is required")
      .min(1, "Number of needy people must be at least 1")
      .max(100000, "Number of needy people must be at least 1"),
    deedValidity: Yup.number().min(1).max(72),
  });

  const deedValidationSchema = Yup.lazy((values) => {
    return arrLen > 0 ? schemaWithArrLen : schemaWithoutArrLen;
  });
  // Generate group option list
  const generateOptionList = () => {
    return groupList.map((item) => (
      <option key={item?.id} value={item?.id}>
        {item?.name}
      </option>
    ));
  };

  const [location, setLocation] = useState({
    lat: 18.564388540316244,
    lng: 73.77707584453584,
  });

  const openModal = () => {
    setModalOpen(true);
  };

  const openSubTypeModal = () => {
    setIsSubTypeOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeSubTypeModal = () => {
    setIsSubTypeOpen(false);
  };

  const handleData = (data) => {
    console.log("Submit data ==> ", data);
    const preferenceList = data?.preference.map(({ name, ...rest }) => rest);

    let groupList =
      data.preferences &&
      data.preferences
        .filter((item) => {
          console.log("Item --> ", item);
          console.log("Type of value --> ", typeof item);
          return typeof item === "number";
        })
        .map((item) => item);

    const formData = new FormData();

    // Append data to the FormData object
    formData.append("tagTypeId", data?.category); //
    formData.append("groupId", data?.group); //
    formData.append("geoLat", location?.lat ? location?.lat : 0); //
    formData.append("geoLng", location?.lng ? location?.lng : 0); //
    formData.append("tagTitle", data?.categoryTitle); //
    formData.append("description", data?.storyOfNeed); //
    formData.append("address", data?.location); //
    formData.append("validity", data?.deedValidity); //
    formData.append("hasContainer", data?.container); //
    formData.append("tagSubTypeQuantityList", JSON.stringify(preferenceList)); //
    formData.append("isPermanentNeedLocation", data?.pLocation); //
    formData.append("audianceAllGroups", data?.allGroups); //
    formData.append("audianceGroupList", groupList); //---------------------------
    formData.append("peopleInNeedCount", data?.needyPeople); //
    data?.deedPicture && formData.append("image", data?.deedPicture); // if image available then send it
    formData.append("audianceAllIndividuals", data?.allIndividuals); //

    try {
      axios
        .post(`${API_SERVER_URL}/api/gc/deed/tagdeed`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("Response POST tag a deed --> ", response);
          // toast.success("Tag a deed created successfully");
          // closeModal();
          handleReset();
          if (response.data.data[0].Result_Code === 200) {
            // popup modal
            const details = () => (
              <>
                <div className="p-4 border border-green-200">
                  <p>
                    You have earned{" "}
                    {response?.data?.data[0]?.New_Earned_Credits} points
                  </p>
                  <p>
                    Your total points are{" "}
                    {response?.data?.data[0]?.Total_Earned_Credits} points
                  </p>
                </div>
              </>
            );

            Swal.fire({
              title: `You have tagged ${data?.categoryTitle} need`,
              html: renderToString(details()),
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
            }).then((result) => {
              if (result.isConfirmed) {
                // Reload the page
                window.location.reload();
              }
            });;
          }
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Tag a deed post error ---> ", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      deedPicture: null,
      deedPictureShow: null,
      group: "",
      category: "",
      categoryTitle: "",
      categoryIcon: null,
      preference: [],
      needyPeople: 1,
      location: "",
      address: {},
      pLocation: false,
      allGroups: false,
      allIndividuals: false,
      deedValidity: 24,
      preferences: "",
      storyOfNeed: "Person is needy",
      container: 0,
    },
    validationSchema: deedValidationSchema,
    onSubmit: handleData,
  });

  const handleReset = () => {
    formik.resetForm();
    setRenderedQuantity([]);
    setSelectedOptions([]);
    setLocation("");
    setDetails("");
  };

  useEffect(() => {
    console.log("quantity ---> ", renderedQuantity);
    formik.setFieldValue("preference", renderedQuantity);
  }, [renderedQuantity]);

  const handlePlaceSelected = (places) => {
    // console.log("places ==> ", places);
    if (!places || !places.geometry || !places.geometry.location) {
      console.error("Invalid place object:", places);
      return;
    }

    const { formatted_address, geometry } = places;
    const { location } = geometry;

    // Update your formik values or state here
    formik.setValues({
      ...formik.values,
      location: formatted_address, // or any other value you want
    });

    let geolocation = { lat: location.lat(), lng: location.lng() };
    setLocation(geolocation);
  };

  const handleDecrease = (subTypeId, subTypeName) => {
    setquantityUpdated((prevQuantity) => {
      const updatedQuantity = { ...prevQuantity };

      if ((updatedQuantity[subTypeId] || 0) > 0) {
        updatedQuantity[subTypeId] = (updatedQuantity[subTypeId] || 0) - 1;
      }

      if (updatedQuantity[subTypeId] === 0) {
        delete updatedQuantity[subTypeId];
      }

      const outputs = Object.keys(updatedQuantity).map((key) => ({
        tagSubTypeId: key,
        name: subDeedList.find((item) => item.Id === parseInt(key, 10)).name,
        quantity: updatedQuantity[key],
      }));

      setOutput(outputs);
      // setRenderedQuantity(output);

      return updatedQuantity;
    });
  };

  const handleIncrease = (subTypeId, subTypeName) => {
    setquantityUpdated((prevQuantity) => {
      const updatedQuantity = { ...prevQuantity };
      console.log("prevQuantity", prevQuantity);
      updatedQuantity[subTypeId] =
        parseInt(updatedQuantity[subTypeId] || 0) + 1;

      const outputs = Object.keys(updatedQuantity).map((key) => ({
        tagSubTypeId: key,
        name: subDeedList.find((item) => item.Id === parseInt(key, 10))
          ? subDeedList.find((item) => item.Id === parseInt(key, 10)).name
          : "",
        quantity: updatedQuantity[key],
      }));

      setOutput(outputs);
      console.log("handleIncrease==outputs===>", outputs);
      // setRenderedQuantity(output);

      return updatedQuantity;
    });
  };

  const handleOnChange = (subTypeId, subTypeName, value) => {
    setquantityUpdated((prevQuantity) => {
      const updatedQuantity = { ...prevQuantity };
      updatedQuantity[subTypeId] = parseInt(value);

      if (
        isNaN(updatedQuantity[subTypeId]) ||
        updatedQuantity[subTypeId] === 0
      ) {
        delete updatedQuantity[subTypeId];
      }

      const outputs = Object.keys(updatedQuantity).map((key) => ({
        tagSubTypeId: key,
        name: subDeedList.find((item) => item.Id === parseInt(key, 10))
          ? subDeedList.find((item) => item.Id === parseInt(key, 10)).name
          : "",
        quantity: updatedQuantity[key],
      }));

      setOutput(outputs);
      // setRenderedQuantity(output);

      return updatedQuantity;
    });
  };

  const handleOK = () => {
    setQuantity(() => quantityUpdated);
    console.log("output", output);
    setRenderedQuantity(output);
  };
  const handleCancel = () => {
    // setQuantity((prev) => prev);
    setquantityUpdated(quantity);
    console.log("output", output);
    // setRenderedQuantity((prev) => prev);
    setOutput(renderedQuantity);
  };

  // const arrLen = subDeedList.length;
  useEffect(() => {
    setArrLen(subDeedList.length);
  }, [subDeedList.length]);

  // Created seperate array to set first deed subtype value = 1 (by default) as per the requirement
  let arr2 = [];
  if (subDeedList && subDeedList) {
    arr2.push(subDeedList[0]);
  }
  const firstSubTypequantity = () => {
    if (arr2 && arr2 && firstSubTypQty) {
      arr2.map((item, index) => {
        console.log("firstsub", item);
        handleIncrease(item?.Id, item?.name);
      });
      setFirstSubTypQty(0);
      handleOK();
      handleCancel();
    }
  };

  const rows = subDeedList.map((item, index) => {
    // index===0&&console.log(item);

    const quantityObject =
      output.length > 0 &&
      output?.find((quantityItem) => {
        return +quantityItem?.tagSubTypeId === item?.Id;
      });
    console.log("quantityObject=========>", quantityObject);
    return (
      <div key={item.Id} className="flex justify-between m-3">
        <div className="">{item.name}</div>
        <div className="flex">
          <p
            onClick={() => handleDecrease(item?.Id, item?.name)}
            className="px-3 rounded bg-slate-300 cursor-pointer"
          >
            -
          </p>
          <div className="">
            <input
              type="text"
              name=""
              min={0}
              id={item.Id}
              value={quantityObject?.quantity ? quantityObject?.quantity : 0}
              onChange={(e) =>
                handleOnChange(item?.Id, item?.name, e.target.value)
              }
              className="w-10 rounded border border-b-2 mx-3 text-center"
            />
          </div>
          <p
            onClick={() => handleIncrease(item?.Id, item?.name)}
            className="px-3 rounded bg-slate-300 cursor-pointer"
          >
            +
          </p>
        </div>
      </div>
    );
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      location: details.address,
    });
  }, [details]);

  // Get group list by id
  const getGroupList = () => {
    try {
      const payload = {
        id: id,
      };

      axios
        .post(`${API_SERVER_URL}/api/gc/grouplist/list`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setGroupList(response?.data?.data);
          const uniqueValuesSet = new Set(optionList.map((item) => item.value));
          const newData = response?.data?.data
            .filter((item) => !uniqueValuesSet.has(item.name))
            .map((item) => ({
              label: item.name,
              value: item.id,
            }));

          setOptionList([...optionList, ...newData]);
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  useEffect(() => {
    getGroupList();
  }, []);

  // Get deed list
  const getDeedList = (groupId) => {
    try {
      axios
        .get(`${API_SERVER_URL}/api/gc/deed/types?forGroupId=${groupId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setDeedList(response?.data?.data);
          console.log("deedList==", response?.data?.data);
        })
        .catch((error) => {
          console.log("Error --> ", error);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  useEffect(() => {
    let groupId = formik.values.group;
    getDeedList(groupId);
  }, [formik.values.group]);

  // Generate deed option list
  const generateDeedOptionList = () => {
    return deedList?.map((item) => (
      <option key={item?.id} value={item?.id}>
        {item?.name}
      </option>
    ));
  };

  // Get sub deed list
  const getSubDeedList = async () => {
    try {
      await axios
        .get(
          `${API_SERVER_URL}/api/gc/deed/subtypes/${formik.values.category}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("subtypes===", response?.data?.subtypes);
          setSubDeedList(response?.data?.subtypes);
          setArrLen(subDeedList.length);
          setFirstSubTypQty(1);
          setLoader(true);
        })
        .catch((error) => {
          console.log("Error --> ", error);
          setArrLen(0);
          setLoader(false);
          if (error?.response && error?.response?.status === 403) {
            logout("Session Expired. Login again");
          }
        });
    } catch (error) {
      console.log("Error --> ", error);
    }
  };

  useEffect(() => {
    getSubDeedList();
    console.log("formik values", formik.values);
  }, [formik.values.category, arrLen]);

  useEffect(() => {
    firstSubTypequantity();
    console.log("firstSubTypequantity called...", subDeedList[0]);
  }, [subDeedList[0]]);

  useEffect(() => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setAllIndividuals(
      selectedValues.includes("All individual users") ? "Y" : "N"
    );
    setAllGroups(selectedValues.includes("All Groups") ? "Y" : "N");
  }, [selectedOptions]);

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleFileChange = (e) => {
    const selectedImage = e?.target?.files[0];
    formik.setFieldValue("deedPicture", selectedImage);
    if (selectedImage) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        formik.setFieldValue("deedPictureShow", base64String);
      };

      reader.readAsDataURL(selectedImage);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-96 absolute">
          <div
            data-drawer-target="default-sidebar"
            data-drawer-toggle="default-sidebar"
            aria-controls="default-sidebar"
            type="button"
            className={`absolute z-20 ${
              isToggle
                ? "left-[-45px]"
                : "lg:right-[-30px] md:right-[-30px] sm:right-[-30px] mobile:right-16"
            }  p-7 mt-2 ms-3 text-sm rounded-full bg-white border-4 border-orange-500  focus:outline-none focus:ring-2  focus:ring-gray-200  dark:focus:ring-gray-6001 transition-transform duration-300`}
            onClick={() => setIsToggle(!isToggle)}
          >
            <span className="sr-only">Open sidebar</span>
            {isToggle ? (
              <FaChevronRight className="cursor-pointer absolute right-1 top-4 text-2xl text-orange-500 hover:text-black transition-colors duration-75" />
            ) : (
              <FaChevronLeft className="cursor-pointer absolute right-0 top-4 text-2xl text-orange-500 hover:text-black transition-colors duration-75" />
            )}
          </div>

          <aside
            id="default-sidebar"
            className={`absolute ${
              isToggle
                ? "w-0 left-0 hidden -translate-x-full"
                : "lg:w-96 md:w-96 sm:w-96 mobile:w-90 lg:left-0 md:left-0 "
            }  z-30 shadow-slate-400 transition-transform duration-700  sm:translate-x-0`}
            aria-label="Sidebar"
          >
            {/* Select Preferences */}
            {isModalOpen && (
              <div className="fixed top-10 inset-0 z-40 bg-opacity-50 flex justify-start max-h-80">
                <div className="bg-white absolute p-4 rounded-md w-80 border m-4">
                  <div className="flex justify-between mb-3">
                    <p className="text-lg font-medium">Select Preferences</p>
                    <button
                      onClick={() => {
                        handleCancel();
                        closeModal();
                      }}
                      className=" text-gray-400 px-4 py-2 rounded-md"
                    >
                      X
                    </button>
                  </div>
                  <hr />
                  <h1>Select preferences for number of people</h1>
                  {arrLen > 0 && <div>{rows}</div>}
                  <hr />
                  <div className="flex justify-between my-4">
                    <button
                      onClick={() => {
                        handleOK();
                        closeModal();
                      }}
                      className="bg-slate-200 text-gray-700 text-base font-semibold rounded p-1 px-2"
                    >
                      OK
                    </button>
                    <button
                      type="button"
                      onClick={openSubTypeModal}
                      className="bg-slate-200 text-gray-700 text-base font-semibold rounded p-1 px-2"
                    >
                      Suggest Sub-Type
                    </button>
                    <button
                      onClick={() => {
                        handleCancel();
                        closeModal();
                      }}
                      className="bg-slate-200 text-gray-700 text-base font-semibold rounded p-1 px-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Suggest Preferences */}

            {isSubTypeOpen && (
              <SubDeedSuggestion
                categoryName={formik.values.categoryTitle}
                categoryId={formik.values.category}
                closeSubTypeModal={closeSubTypeModal}
              />
            )}
            <div className="text-center text-white bg-orange-500">
              <h2 className="text-2xl px-10 py-2">Tag A Deed</h2>
            </div>
            <div className="overflow-y-scroll overflow-hidden bg-gray-50 h-full">
              <ul className="space-y-2 lg:h-[100vh] md:h-[75.4vh] sm:max-h-[75.4vh]  mobile:max-h-[87.7vh]">
                <li className="">
                  <div className="flex flex-col">
                    <input
                      type="file"
                      id="deedPicture"
                      name="deedPicture"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="deedPicture">
                      <img
                        src={formik.values.deedPictureShow || placeholder_image}
                        className="w-full h-40 cursor-pointer"
                        alt={
                          formik.values.deedPictureShow
                            ? "Tag a Deed Image"
                            : "Placeholder Image"
                        }
                        title="Select image"
                        // onClick={() =>
                        //   document.getElementById("deedPicture").click()
                        // }
                      />
                    </label>
                    {formik.errors.deedPicture &&
                      formik.touched.deedPicture && (
                        <div className="text-red-500">
                          {formik.errors.deedPicture}
                        </div>
                      )}
                  </div>
                </li>
                <li className="">
                  <div className="flex flex-col mx-3 my-4">
                    <label htmlFor="cars" className="mb-1 text-gray-500">
                      From Group
                    </label>
                    <select
                      name="group"
                      id="group"
                      className="p-2 border rounded-md cursor-pointer"
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue("category", "");
                      }}
                      value={formik.values.group}
                    >
                      <option value="" disabled hidden>
                        Select Group
                      </option>
                      {generateOptionList()}
                    </select>
                    {formik.errors.group && formik.touched.group && (
                      <div className="text-red-500">{formik.errors.group}</div>
                    )}
                  </div>
                </li>
                <li>
                  <div className="flex flex-row align-middle justify-between content-center mx-3 my-auto">
                    <div className="flex flex-wrap flex-col">
                      <label htmlFor="category" className="mb-1 text-gray-500">
                        Category
                      </label>
                      <select
                        name="category"
                        id="category"
                        className={`p-2 border rounded-md cursor-pointer ${
                          formik.values.categoryIcon ? "w-full" : "w-[150%]"
                        }`}
                        onChange={(e) => {
                          setLoader(false);
                          formik.handleChange(e);
                          const selectedOption = deedList.find((item) => {
                            return item?.id === +e.target.value;
                          });
                          console.log("selectedOption", selectedOption);
                          if (selectedOption) {
                            setQuantity({});
                            setquantityUpdated({});
                            formik.setValues({
                              ...formik.values,
                              container: 0,
                              category: e.target.value,
                              categoryTitle: selectedOption?.name,
                              categoryIcon: `${API_SERVER_URL}/${selectedOption?.Icon_Path}`, // Assuming 'Icon' is the property for category icon
                            });
                          }
                          setRenderedQuantity(() => []);
                          setOutput(() => []);

                          console.log("renderedQuantity", renderedQuantity);
                        }}
                        disabled={formik.values.group === ""}
                        value={formik.values.category}
                      >
                        <option value="" disabled hidden>
                          Select Category
                        </option>
                        {generateDeedOptionList()}
                      </select>
                    </div>

                    {/* {formik.values.categoryIcon && (
                      <div>
                        <img
                          width="50px"
                          className="mt-4 ml-5"
                          src={formik.values.categoryIcon}
                          alt="categoryIcon"
                        />
                      </div>
                    )} */}
                  </div>

                  {formik.errors.category && formik.touched.category && (
                    <div className="text-red-500 ml-4">
                      {formik.errors.category}
                    </div>
                  )}
                </li>
                <li>
                  {arrLen && loader ? (
                    <div className="flex flex-col mx-3 my-4">
                      <label htmlFor="preferences" className="custom_label">
                        Select Preferences
                      </label>
                      <div
                        className={`bg-slate-100 cursor-pointer ${
                          formik.values.category ? "" : "disabled"
                        }`}
                        onClick={() => {
                          if (formik.values.category) {
                            openModal();
                          } else {
                            toast.info(
                              "Please select a group and category before selecting the preferences"
                            );
                          }
                        }}
                      >
                        {/* Render the selected category text or placeholder */}
                        <textarea
                          name="preferences"
                          id="preferences"
                          rows={1}
                          className="p-2 border rounded-md cursor-pointer w-full"
                          readOnly
                          value={
                            Object.keys(renderedQuantity).length === 0
                              ? "Select Preferences"
                              : Object.entries(renderedQuantity)
                                  .map(
                                    ([name, quantity]) =>
                                      `${quantity.name}: ${quantity.quantity}`
                                  )
                                  .join(", ")
                          }
                        />
                      </div>
                      {formik.errors.preference && (
                        <p className="text-red-500">
                          {formik.errors.preference}
                        </p>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  <div className="flex flex-col mx-3 my-4">
                    <label htmlFor="cars" className="mb-2 text-gray-500">
                      Select Number of needy people
                    </label>
                    <div className="flex justify-around">
                      <div>
                        <span>Needy People</span>
                      </div>
                      <div className="flex">
                        <p
                          onClick={() => {
                            formik.setFieldValue(
                              "needyPeople",
                              Math.max(1, formik.values.needyPeople - 1)
                            );
                          }}
                          disabled={formik.values.needyPeople === 1}
                          className="px-3 rounded bg-slate-300 cursor-pointer"
                        >
                          -
                        </p>
                        <div>
                          <input
                            type="number"
                            min={1}
                            max={100000}
                            name="needyPeople"
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10); // Parse the input value as an integer
                              if (newValue === 0 || isNaN(newValue)) {
                                // Show error message or handle it as per your UI/UX
                                console.error("Value cannot be zero");
                                // Optionally, you can set the value to 1 automatically
                                formik.setFieldValue("needyPeople", 1);
                              } else {
                                formik.handleChange(e);
                              }
                            }}
                            value={formik.values.needyPeople}
                            className="w-20 border border-b-2 mx-3 text-center"
                            required
                          />
                        </div>
                        <p
                          onClick={() => {
                            formik.setFieldValue(
                              "needyPeople",
                              formik.values.needyPeople + 1
                            );
                          }}
                          className="px-3 rounded bg-slate-300 cursor-pointer"
                        >
                          +
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                {formik.values.category === "1" && (
                  <>
                    <li>
                      <div className="flex mx-3 my-4 w-full">
                        <div className="">
                          <div className="text-gray-500 text-lg">
                            <input
                              type="checkbox"
                              name="container"
                              id="container"
                              value={formik.values.container}
                              onChange={(e) => {
                                console.log(e.target.value);
                                formik.setFieldValue(
                                  "container",
                                  formik.values.container === 1 ? 0 : 1
                                );
                              }}
                            />{" "}
                            Container Available
                          </div>
                          <p>
                            * Whether that needy person has a food plate/water
                            container to receive the donated food/water?
                          </p>
                        </div>
                      </div>
                    </li>
                  </>
                )}
                <li>
                  <div className="flex flex-col my-4 w-full">
                    <div className="flex items-center justify-between">
                      {/* <ReactGoogleAutocomplete
                        apiKey={GOOGLE_KEY}
                        onPlaceSelected={handlePlaceSelected}
                        inputElement={<input type="text" name="" id="" value={details.address}/>}
                      /> */}
                      <Places
                        location={location}
                        setLocation={setLocation}
                        details={details}
                        setDetails={setDetails}
                      />
                      <span
                        className="text-gray-500 cursor-pointer hover:text-gray-700 mx-3 mt-7"
                        onClick={() => setLocation("")}
                      >
                        X
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col mx-3 my-4">
                    <label className="relative inline-flex items-center mb-5 cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        checked={formik.values.pLocation === true}
                        onChange={() => {
                          formik.setFieldValue(
                            "pLocation",
                            formik.values.pLocation === true ? false : true
                          );
                        }}
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-400"></div>
                      <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-900">
                        Permanent location
                      </span>
                    </label>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col mx-3 my-4">
                    <div className="flex justify-between">
                      <p className="text-gray-500">
                        Deed Validity (1 to 72 Hours)
                      </p>
                      <p>{`${formik.values.deedValidity}hr(s)`}</p>
                    </div>
                    <div>
                      <input
                        min={1}
                        max={72}
                        id="validity"
                        type="range"
                        name="deedValidity"
                        value={formik.values.deedValidity}
                        onChange={formik.handleChange}
                        className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-400 "
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col mx-3 my-4">
                    <label htmlFor="multiSelect" className="mb-2 text-gray-500">
                      Audience
                    </label>
                    <Select
                      isMulti
                      options={optionList}
                      value={selectedOptions}
                      required
                      onChange={(selected) => {
                        setSelectedOptions(selected);
                        const selectedValues = selected.map(
                          (option) => option.value
                        );
                        formik.setFieldValue("preferences", selectedValues);
                        console.log("selectedValues ---> ", selectedValues);
                        // Update "All Groups" and "All Individual Users" based on selected values
                        formik.setFieldValue(
                          "allGroups",
                          selectedValues.includes("All Groups") ? true : false
                        );
                        formik.setFieldValue(
                          "allIndividuals",
                          selectedValues.includes("All individual users")
                            ? true
                            : false
                        );
                      }}
                    />
                  </div>
                </li>
                <li>
                  <div className="flex flex-col mx-3 my-4">
                    <label htmlFor="">Description</label>
                    <textarea
                      id=""
                      onChange={formik.handleChange}
                      name="storyOfNeed"
                      cols="10"
                      value={formik.values.storyOfNeed}
                      rows="2"
                      className=" p-2 my-2 mb-10 border"
                      placeholder="Enter story"
                      required
                    ></textarea>
                  </div>
                </li>
              </ul>
            </div>
            <div className="text-center flex text-white">
              <button
                type="submit"
                className="text-white border-r-2 border-gray-500 w-full bg-orange-500 hover:text-black focus:ring-4  font-medium  text-sm px-5 py-2.5"
              >
                POST
              </button>
              <button
                type="button"
                onClick={handleReset}
                className="text-white w-full bg-orange-500 hover:text-black focus:ring-4  font-medium  text-sm px-5 py-2.5"
              >
                RESET
              </button>
            </div>
          </aside>
        </div>
      </form>
      <div className="">
        <div className="google-map-div">
          <GoogleMaps
            location={location}
            setLocation={setLocation}
            details={details}
            setDetails={setDetails}
          />
        </div>
      </div>
    </>
  );
};

export default TagADeed;
