import logo from "./contentlogo.png";
import placeholder_image from "./placeholder_images.png";
import character from "./instruction/character.png";
import icon from "./instruction/icon.png";
import square from "./instruction/square.png";
import waterIcon from "./instruction/image015.png";
import waterCharacter from "./instruction/image013.png";
import foodIcon from "./instruction/image011.png";
import foodCharacter from "./instruction/image009.png";
import characterFormat1 from "./instruction/image001.png";
import characterFormat2 from "./instruction/image003.png";
import iconFormat1 from "./instruction/image005.png";
import iconFormat2 from "./instruction/image007.png";

export {
  logo,
  placeholder_image,
  character,
  icon,
  square,
  waterCharacter,
  waterIcon,
  foodIcon,
  foodCharacter,
  characterFormat1,
  characterFormat2,
  iconFormat1,
  iconFormat2,
};
