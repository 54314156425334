import { useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { GOOGLE_KEY, LIBRARIES } from "../app_constants";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export default function Places({ location, setLocation, details, setDetails }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_KEY,
    libraries: LIBRARIES,
  });
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Map
      location={location}
      setLocation={setLocation}
      details={details}
      setDetails={setDetails}
    />
  );
}

const Map = ({ location, setLocation, details, setDetails }) => {
  const [selected, setSelected] = useState(null);
  return (
    <div>
      <PlacesAutocomplete
        selected={selected}
        setSelected={setSelected}
        location={location}
        setLocation={setLocation}
        details={details}
        setDetails={setDetails}
      />
    </div>
  );
};

const PlacesAutocomplete = ({
  selected,
  setSelected,
  location,
  setLocation,
  details,
  setDetails,
}) => {
  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const [isSuggestion,setIsSuggestion] = useState(false)

  const handleSelect = async (address) => {
    setValue(address, false);
    const result = await getGeocode({ address });
    const { lat, lng } = await getLatLng(result[0]);
    console.log("Lat lng --> ", lat, lng);
    setSelected({ lat, lng });
    setLocation({ lat, lng });
    clearSuggestions();
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  
  useEffect(() => {
    // Check if the current value is different from details.address
    const currentAddress = value;
    const newAddress = details.address;

    if (currentAddress !== newAddress) {
      setValue(newAddress);
    }
    setIsSuggestion(false)
    clearSuggestions();
  }, [details.address]);

  return (
    <div className="flex flex-col mx-3 my-4 relative w-[140%]">
      <label htmlFor="location" className="mb-2 text-gray-500">
        Search and Select Location
      </label>
      <input
        type="text"
        id="location"
        name="location"
        value={value}
        onFocus={()=>setIsSuggestion(true)}
        onChange={handleChange}
        placeholder="Search and select a location..."
        className="p-2 border rounded-md"
        required
      />
      {status === "OK" && value && isSuggestion && (
        <ul className="p-2 border absolute left-0 top-[70px] z-10 mt-2 bg-white rounded-md">
          {data.map(({ place_id, description }) => (
            <li
              key={place_id}
              onClick={() => handleSelect(description)}
              className="cursor-pointer hover:bg-gray-200 p-2"
            >
              {description}
            </li>
          ))}
        </ul>
      )}
      {/* {selected && (
        <p className="mt-2">
          Selected Location: {selected.lat}, {selected.lng}
        </p>
      )} */}
    </div>
  );
};
