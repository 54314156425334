import React from "react";
import TagADeed from "./TagADeed";

const Home = () => {
  return (
    <>
      <div className="pt-[4.5rem]">
        <TagADeed />
      </div>
    </>
  );
};

export default Home;
