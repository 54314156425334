// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.google-map-div {
  height: 90vh;
  width: 100vw;
  position: "absolute";
}
@media screen and (max-width: 576px) {
  /* Styles for small screens go here */
  .google-map-div {
    height: 108.5vh;
    width: 119vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,qCAAqC;EACrC;IACE,eAAe;IACf,YAAY;EACd;AACF","sourcesContent":[".google-map-div {\n  height: 90vh;\n  width: 100vw;\n  position: \"absolute\";\n}\n@media screen and (max-width: 576px) {\n  /* Styles for small screens go here */\n  .google-map-div {\n    height: 108.5vh;\n    width: 119vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
