import "./App.css";
import React, { createContext, useContext, useState } from "react";
import { Route, Routes, Navigate, Router, Redirect } from "react-router-dom";
import Login from "./pages/Auth/Login";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import CustomCategory from "./pages/CustomCategory";
import Reports from "./pages/Reports";
import { useAuth } from "./AuthContext";
import { ToastContainer } from "react-toastify";
import ImageInstruction from "./pages/ImageInstruction";

function App() {
  const { id } = useAuth().getDetails();
  return (
    <>
      <ToastContainer autoClose={2000} position="top-center" />
      {id && <Navbar />} {/* Render Navbar only if authenticated */}
      <Routes>
        {id && (
          <>
            <Route path="/auth" element={<Navigate to="/" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
        {!id && <Route path="/auth" element={<Login />} />}
        {id && (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/custom-category" element={<CustomCategory />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/image-instruction" element={<ImageInstruction />} />
          </>
        )}
        {!id && <Route path="*" element={<Navigate to="/auth" />} />}
      </Routes>
    </>
  );
}

export default App;
