// AuthContext.js

import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AuthContext = createContext(null);
const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const login = (id, token) => {
    setAuthenticated(true);
    localStorage.setItem("gc_admin_id", id);
    localStorage.setItem("gc_token", token);
  };

  const getDetails = () => {
    const id = localStorage.getItem("gc_admin_id");
    const token = localStorage.getItem("gc_token");
    return { id, token };
  };

  const logout = (msg) => {
    toast.info(msg,{toastId: msg,});
    localStorage.removeItem("gc_admin_id");
    localStorage.removeItem("gc_token");
    setTimeout(() => {
      navigate("/auth");
    }, 1000);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, getDetails }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
