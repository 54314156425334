import React from "react";
import { FaDownload } from "react-icons/fa";
import * as XLSX from "xlsx";
import { API_SERVER_URL } from "../constants";
import { toast } from "react-toastify";

const FullfilledDeedsExcelConvetor = ({
  jsonData,
  excelFileName,
  excelTitle,
  selectedGroupId,
}) => {
  const convertJsonToExcel = () => {
    const formatDate = (dateString) => {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return new Date(dateString).toLocaleDateString("en-US", options);
    };

    let userDetails = jsonData?.groupList[0];
    console.log("UserDetails --> ", jsonData?.userData);

    const modifiedData = jsonData?.userData?.map((item) => ({
      "Fulfilled Id": item?.Fulfilled_ID, //
      "Tagged Id": item?.Tagged_ID, //
      "Category Type": item?.Tagged_Title, //
      "Deed Type": item?.deed_type, //
      "Deed Sub Type": item?.deed_sub_type, //
      Description: item?.Description, //
      "Fulfilled By": item?.fulfilled_by, //
      "Photo link":
        item?.Fulfilled_Photo_Path &&
        `${API_SERVER_URL}/${item?.Fulfilled_Photo_Path}`, //
      Address: item?.Address, //
      "Geographical points": item?.Geopoint, //
      "Fulfilled date and time": formatDate(item?.Fullfilled_Datetime), //
      "People Benefited": item?.fulfilled_count, //
      Partially_Fulfilled:
        item && item?.Partially_Fulfilled === "Y"
          ? "Partially Fulfilled"
          : item && item?.Partially_Fulfilled === "N"
          ? "Completely Fulfilled"
          : "", //
    }));

    const ws = XLSX.utils.json_to_sheet(modifiedData, { origin: "A9" });

    // Set title
    ws["C1"] = { t: "s", v: excelTitle };

    ws["A3"] = { t: "s", v: "Group Id :" };
    ws["B3"] = { t: "s", v: userDetails?.id };

    ws["C3"] = { t: "s", v: "Group Name :" };
    ws["D3"] = { t: "s", v: userDetails?.name };

    ws["A4"] = { t: "s", v: "Group Creation Date Time :" };
    ws["B4"] = { t: "s", v: formatDate(userDetails?.created_at) };

    ws["A5"] = { t: "s", v: "Group Description :" };
    ws["B5"] = { t: "s", v: userDetails?.description };

    ws["C7"] = { t: "s", v: "Fulfilled Deeds" };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, excelFileName, { compression: true });
  };

  const handleDownloadClick = () => {
    if (selectedGroupId !== null) {
      convertJsonToExcel();
    } else {
      toast.error("Please select a group");
    }
  };

  return (
    <div>
      <button
        data-toggle="tooltip"
        data-placement="top"
        title="Download List"
        className="text-white flex ml-4 mt-2 bg-orange-500 hover:text-black font-medium rounded text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none dark:focus:ring-blue-800"
        onClick={handleDownloadClick}
      >
        <FaDownload className="text-base mr-1" /> Download
      </button>
    </div>
  );
};

export default FullfilledDeedsExcelConvetor;
